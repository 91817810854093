import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Guardian } from '@/views/all-guardians/interfaces/guardians.interface';
import * as _ from 'lodash';

type Response = {
  data: Guardian[];
  totalCount: number;
};
interface Params {
  skip: number;
  take: number;
  showDeleted?: boolean;
  sortField?: string | null;
  sortOrder?: 'ASC' | 'DESC';
  clientId?: string;
  search?: string;
}

export type Options = Omit<UseQueryOptions<unknown, DefaultError, Response>, 'queryKey' | 'queryFn'>;

export const useGuardiansQuery = (params: Params, options?: Options) => {
  const queryParams = {
    skip: params.skip,
    take: params.take,
    showDeleted: params.showDeleted,
    sortField: params.sortField,
    sortOrder: params.sortOrder,
    clientIdFilter: params.clientId,
    search: params.search
  };

  const cleanedQueryParams = _.omitBy(queryParams, (value) => _.isNil(value) || value === '');

  const _query = async (): Promise<Response> => {
    const { data } = await axiosInterceptorInstance.get('guardians', {
      params: cleanedQueryParams
    });
    return data;
  };

  return useQuery({
    queryKey: ['guardians', queryParams],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
