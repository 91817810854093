import { Button, Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';

import { useTranslations } from 'next-intl';
import { createRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Guardian } from '../interfaces/guardians.interface';
import { Chips, LabelValueItem, toast, ToastAction, ToastTypeEnums } from '@/components/common';
import { toMaskByString } from '@/components/form';
import { useIsAllowed } from '@/lib/RBAC';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';
import { PreviewClientDialog, PreviewClientDialogRef } from '@/views/all-clients/preview/preview-client.dialog';
import { Colors } from '@/common/enums';
import { useGuardianQuery } from '@/hooks/fetchers/queries/guardians/useGuardianQuery';
import { ScrollArea } from '@radix-ui/react-scroll-area';
import { compileFullName, compileLastName } from '@/lib/utils';
import { UserTypes, useUserResendLinkMutation } from '@/hooks/fetchers/mutations/useUserResendLinkMutation';
import { ManageGuardianDialog, ManageGuardianDialogRef } from '../manage/manage-guardian.dialog';
import { useRemoveGuardianMutation } from '@/hooks/fetchers/mutations/guardians/useRemoveGuardianMutation';
import { useRemoveUndoGuardianMutation } from '@/hooks/fetchers/mutations/guardians/useRemoveUndoGuardianMutation';

interface Props {
  onUpdated?: () => void;
  onRemove?: (id: string) => void;
}

export type PreviewGuardianDialogRef = {
  open: (guardian: Guardian) => void;
  openById: (id: string) => void;
};

const PreviewGuardianDialog = forwardRef<PreviewGuardianDialogRef, Props>(({ onUpdated, onRemove }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const t = useTranslations();
  const [guardianProfile, setGuardianProfile] = useState<Guardian | null>(null);
  const [guardianId, setGuardianId] = useState<string>();
  const { checkPermissions } = useIsAllowed();
  const previewClientDialogRef = createRef<PreviewClientDialogRef>();
  const manageGuardianDialogRef = createRef<ManageGuardianDialogRef>();

  const { refetch: refetchGuardian, data: guardianProfileById } = useGuardianQuery(guardianId, {
    enabled: false,
    refetchOnWindowFocus: isOpen,
  });

  useImperativeHandle(ref, () => ({
    open: (guardian) => {
      setIsOpen(true);
      setGuardianProfile(guardian);
      setGuardianId(guardian.id);
    },
    openById: (id) => {
      setIsOpen(true);
      setGuardianId(id);
    },
  }));

  useEffect(() => {
    refetchGuardian();
  }, [guardianId]);

  useEffect(() => {
    if (!isOpen && guardianId) {
      setGuardianId(undefined);
    }
  }, [isOpen]);

  const { mutate: resendLink, isPending } = useUserResendLinkMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
      });
      onUpdated?.();
    },
  });

  const { mutate: removeGuardian } = useRemoveGuardianMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successDeleted'),
        action: (
          <ToastAction onClick={() => removeUndo(data.id)} altText="Cancel remove">
            Undo action
          </ToastAction>
        ),
      });
      setIsOpen(false);
      onUpdated?.();
      onRemove?.(data.id);
    },
  });

  const { mutate: removeUndo } = useRemoveUndoGuardianMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successRestored'),
      });
      onUpdated?.();
    },
  });

  const previewClient = (id: string) => {
    previewClientDialogRef.current?.openById(id);
  };

  const guardian = guardianProfileById ? guardianProfileById : guardianProfile;

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-screen-xl">
          <DialogHeader>
            <DialogTitle>{t('Pages.AllGuardians.guardianProfile')}</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          <ScrollArea className="h-[calc(100vh-260px)] overflow-auto pr-5">
            <div className="mb-6 flex w-full max-w-[676px] flex-col space-y-6">
              <LabelValueItem label={t('Forms.firstName.label')} value={guardian?.firstName} />
              <LabelValueItem label={t('Forms.lastName.label')} value={compileLastName(guardian as Guardian)} />
              <LabelValueItem label={t('Forms.email.label')} value={guardian?.email} />
            </div>
            <div className="grid grid-cols-form-cols-2 gap-8 pl-1">
              <LabelValueItem
                label={t('Forms.primaryNumber.label')}
                value={guardian ? toMaskByString(guardian.primaryNumber) : undefined}
              />
              <LabelValueItem
                label={t('Forms.secondaryNumber.label')}
                value={guardian ? toMaskByString(guardian.secondaryNumber) : undefined}
              />
              <LabelValueItem label={t('Forms.primaryRelationship.label')} value={guardian?.primaryRelationship} />
              <LabelValueItem label={t('Forms.secondaryRelationship.label')} value={guardian?.secondaryRelationship} />
            </div>

            <div className="mt-6 flex w-full max-w-[676px] flex-col space-y-6">
              <LabelValueItem
                label={t('Forms.speakingLanguage.label')}
                value={
                  guardian && guardian.languages && guardian.languages.length > 0
                    ? guardian.languages.join(', ')
                    : undefined
                }
              />
              <LabelValueItem label={t('Forms.clients.label')}>
                <div className="flex flex-wrap">
                  {guardian &&
                    guardian.clients &&
                    guardian.clients.map((client) => (
                      <Chips
                        key={client.id}
                        color={Colors.indigo}
                        title={compileFullName(client)}
                        onClickAction={() => previewClient(client.id)}
                      />
                    ))}
                </div>
              </LabelValueItem>
            </div>
          </ScrollArea>
          <DialogFooter className="flex h-20 flex-row items-center justify-end space-x-2 border-t border-t-gray-300">
            {!guardian?.deactivatedAt && (
              <>
                {checkPermissions([PERMISSIONS_TYPES.RESEND_INVITATION_LINK]) && !guardian?.isAuthorizationFinished ? (
                  <Button
                    disabled={isPending}
                    type="button"
                    size="lg"
                    variant="outline"
                    className="mt-5"
                    onClick={() => resendLink({ id: guardian?.id as string, type: UserTypes.GUARDIAN })}
                  >
                    {t('Buttons.resendLink')}
                  </Button>
                ) : null}
                {checkPermissions([PERMISSIONS_TYPES.EDIT_GUARDIAN]) && (
                  <Button
                    type="button"
                    size="lg"
                    className="mt-5"
                    onClick={() => manageGuardianDialogRef.current?.open(guardian as Guardian)}
                  >
                    {t('Buttons.edit')}
                  </Button>
                )}
              </>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <ManageGuardianDialog
        onUpdated={() => {
          refetchGuardian();
          onUpdated?.();
        }}
        onDelete={(id) => removeGuardian(id)}
        ref={manageGuardianDialogRef}
      />
      <PreviewClientDialog onUpdated={refetchGuardian} ref={previewClientDialogRef} />
    </>
  );
});

PreviewGuardianDialog.displayName = 'PreviewGuardianDialog';

export { PreviewGuardianDialog };
