import { Button, Dialog } from '@/components/ui';
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { useTranslations } from 'next-intl';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Form, FormControl, FormField, FormItem, FormMessage, Textarea } from '@/components/form';
import { toast, ToastTypeEnums } from '@/components/common';
import { useDeclineServiceMutation } from '@/hooks/fetchers/mutations/services/useDeclineServiceMutation';

const FormSchema = z.object({
  notes: z.string().min(1, { message: 'Forms.note.required' }).max(1000, { message: 'Forms.note.max' }),
});

interface Props {
  onDecline: () => void;
}

export type DeclineServiceDialogRef = {
  open: (clientId: string) => void;
};

const DeclineServiceDialog = forwardRef<DeclineServiceDialogRef, Props>(({ onDecline }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const t = useTranslations();
  const [clientId, setClientId] = useState('');

  useImperativeHandle(ref, () => ({
    open: (clientId) => {
      setIsOpen(true);
      setClientId(clientId);
    },
  }));

  const { mutate: decline, isPending } = useDeclineServiceMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.declineService'),
      });
      setIsOpen(false);
      onDecline();
    },
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    mode: 'onChange',
    resolver: zodResolver(FormSchema),
  });

  function onSubmitHandle(data: z.infer<typeof FormSchema>) {
    decline({id: clientId, note: data.notes});
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>{t('Pages.MyClients.declineService')}</DialogTitle>
          <DialogDescription>{t('Pages.MyClients.reasonForDeclining')}</DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmitHandle)} className="space-y-4 pt-4">
            <div className="relative mb-10">
              <FormField
                control={form.control}
                name="notes"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormControl>
                      <Textarea placeholder={t('Forms.note.placeholder')} className="resize-none" {...field} />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <DialogFooter className="mt-10 flex h-20 flex-row items-center justify-end space-x-2">
              <Button
                type="submit"
                disabled={isPending || !form.formState.isValid}
                variant="destructive"
                size="lg"
                className="mt-5"
              >
                {t('Buttons.declineService')}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
});

DeclineServiceDialog.displayName = 'DeclineServiceDialog';

export { DeclineServiceDialog };
