import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { TagItem } from '@/components/form';
import { Therapist } from '@/views/all-therapists/interfaces/therapists.interface';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as _ from 'lodash';
import { stringify } from 'qs';

type Response = {
  data: Therapist[];
  totalCount: number;
};
interface Params {
  skip: number;
  take: number;
  search?: string;
  supervisorId?: string;
  showDeleted?: boolean;
  sortField?: string | null;
  sortOrder?: 'ASC' | 'DESC';
  accessLevels?: TagItem[];
  serviceRoleType?: string;
  serviceRole?: string;
  regionalCenter?: string;
  language?: string;
}
export type Options<T> = Omit<UseQueryOptions<unknown, DefaultError, T>, 'queryKey' | 'queryFn'>;

export const useTherapistsQuery = <T = Response>(params: Params, options?: Options<T>) => {
  const queryParams = {
    skip: params.skip,
    take: params.take,
    search: params.search,
    showDeleted: params.showDeleted,
    supervisorIdFilter: params.supervisorId,
    sortField: params.sortField,
    sortOrder: params.sortOrder,
    serviceRoleFilter: params.serviceRole,
    regionalCenterFilter: params.regionalCenter,
    languageFilter: params.language,
    'accessLevelFilter[]': params.accessLevels?.map((value) => value.label), // Array
    serviceRoleTypeFilter: params.serviceRoleType,
  };

  const cleanedQueryParams = _.omitBy(queryParams, value => _.isNil(value) || value === '');

  const _query = async (): Promise<Response> => {
    const { data } = await axiosInterceptorInstance.get('therapists', {
      params: cleanedQueryParams,
      paramsSerializer: (params) => {
        return stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return data;
  };

  return useQuery({
    queryKey: ['therapists', queryParams],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
