import { sortOrderValueToAPIParams } from '@/components/common';
import { SortingState } from '@tanstack/react-table';

export const allServicesSortingHelper = (
  sorting: SortingState
): {
  sortField: string | null;
  sortOrder: 'ASC' | 'DESC';
} | null => {
  let sortField = null;
  const preParsing = sortOrderValueToAPIParams(sorting);

  if (preParsing) {
    switch (preParsing.id) {
      case 'Date added':
        sortField = 'createdAt';
        break;
      case 'Hours worked':
        sortField = 'hoursProvided';
        break;
      case 'Initial visit':
        sortField = 'initialVisitDate';
        break;
    }
    return {
      sortField,
      sortOrder: preParsing.sortOrder,
    };
  }
  return null;
};
