'use client';

import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '@/components/ui';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  TagsSelector,
  TagItem,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { cn } from '@/lib/utils';
import { REGIONAL_CENTERS, SERVICE_ROLE_TYPES, SERVICE_ROLES, SHIFT_START_TIMES, SHIFT_STATUSES_TAGS } from '@/common/constants';
import { useIsAllowed } from '@/lib/RBAC';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';
import { SHIFT_STATUS_TYPES } from '@/common/enums';

let SHIFT_STATUSES = SHIFT_STATUSES_TAGS();
export interface FilterAllClientsParams {
  regionalCenter?: string;
  search?: string;
  status?: TagItem[];
  location?: string;
  serviceRoleType?: string;
  startTime?: string;
}

interface Props {
  value?: FilterAllClientsParams;
  onApply: (value: FilterAllClientsParams) => void;
  onSaveAsDefault: (value: FilterAllClientsParams) => void;
  onCancel: () => void;
}

export function AllClientsFilterForm({ value, onApply, onSaveAsDefault, onCancel }: Props) {
  const t = useTranslations();
  const { checkPermissions } = useIsAllowed();

  if(!checkPermissions([PERMISSIONS_TYPES.VIEW_CANCEL_SHIFTS])) {
    SHIFT_STATUSES = SHIFT_STATUSES.filter(status => status.label !== SHIFT_STATUS_TYPES.CANCELED)
  }

  const form = useForm<FilterAllClientsParams>({
    defaultValues: {
      regionalCenter: value ? value.regionalCenter : '',
      status: value ? value.status : [],
      location: value ? value.location : '',
      serviceRoleType: value ? value.serviceRoleType : '',
      startTime: value ? value.startTime : '',
    },
  });

  const onSubmit: SubmitHandler<FilterAllClientsParams> = (data: FilterAllClientsParams): void => {
    onApply(data);
  };

  return (
    <Form {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <div className="pl-1 pr-4">
          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="regionalCenter"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.regionalCenter.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.regionalCenter.placeholderSelector')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        {REGIONAL_CENTERS.map((rc) => (
                          <SelectItem key={rc} value={rc}>
                            {rc}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>

          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="status"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.status.label')}</FormLabel>
                  <FormControl>
                    <TagsSelector
                      data={SHIFT_STATUSES}
                      values={value ? value.status : []}
                      onChoose={field.onChange}
                      placeholder={t('Forms.status.placeholderSelector')}
                      className={cn('', !!fieldState.error && 'border-destructive')}
                    />
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>

          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="location"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.setting.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.setting.placeholderSelector')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        {SERVICE_ROLES.map((item) => (
                          <SelectItem key={item} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>

          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="startTime"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.startTime.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.startTime.placeholderSelector')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        {SHIFT_START_TIMES.map((item) => (
                          <SelectItem key={item} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>

          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="serviceRoleType"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.serviceRoleType.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.serviceRoleType.placeholderSelector')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        {SERVICE_ROLE_TYPES.map((rc) => (
                          <SelectItem key={rc} value={rc}>
                            {rc}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>
        </div>

        <footer className="flex flex-row items-center justify-end space-x-2">
          <Button onClick={onCancel} variant="outline" type="button" size="sm" className="mt-8">
            {t('Buttons.cancel')}
          </Button>
          <Button
            onClick={() => {
              onSaveAsDefault(form.getValues());
            }}
            type="button"
            variant="outline"
            size="sm"
            className="mt-8"
          >
            {t('Buttons.save')}
          </Button>
          <Button type="submit" size="sm" className="mt-8">
            {t('Buttons.apply')}
          </Button>
        </footer>
      </form>
    </Form>
  );
}
