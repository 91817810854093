import { Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';

import { useTranslations } from 'next-intl';
import { createRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Client } from '../interfaces/clients.interface';
import { ClientProfileTabs } from '../enums/client-profile-tabs';
import { ClientProfileTab } from './client-profile-tab';
import { useClientQuery } from '@/hooks/fetchers/queries/clients/useClientQuery';
import { AllServicesTable } from '@/views/all-services/table/all-services-table';
import { AllGuardiansTable } from '@/views/all-guardians/table/all-guardians-table';
import { AllShiftsTable } from '@/views/all-shifts/table/all-shifts-table';
import { AllDocumentsTable } from '@/views/all-documents/table/all-documents-table';
import { ManageClientDialog, ManageClientDialogRef } from '../manage/manage-client.dialog';
import { useRemoveClientMutation } from '@/hooks/fetchers/mutations/clients/useRemoveClientMutation';
import { toast, ToastAction, ToastTypeEnums } from '@/components/common';
import { useRemoveUndoClientMutation } from '@/hooks/fetchers/mutations/clients/useRemoveUndoClientMutation';

interface Props {
  isCanEdit?: boolean;
  onRemove?: (id: string) => void;
  onUpdated?: () => void;
}

export type PreviewClientDialogRef = {
  open: (client: Client, tab?: ClientProfileTabs) => void;
  openById: (id: string, tab?: ClientProfileTabs) => void;
};

const PreviewClientDialog = forwardRef<PreviewClientDialogRef, Props>(({ isCanEdit = true, onRemove, onUpdated }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [defaultTab, setDefaultTab] = useState<ClientProfileTabs>(ClientProfileTabs.INFO);
  const [clientId, setClientId] = useState<string>();
  const [clientProfile, setClientProfile] = useState<Client | null>(null);

  const manageClientDialogRef = createRef<ManageClientDialogRef>();
  const t = useTranslations();

  const { refetch: refetchClient, data: clientProfileById } = useClientQuery(clientId, { enabled: false, refetchOnWindowFocus: isOpen });

  const { mutate: removeClient } = useRemoveClientMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successDeleted'),
        action: (
          <ToastAction onClick={() => removeUndoClient(data.id)} altText="Cancel remove">
            Undo action
          </ToastAction>
        ),
      });
      setIsOpen(false);
      onUpdated?.();
      onRemove?.(data.id);
    },
  });

  const { mutate: removeUndoClient } = useRemoveUndoClientMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successRestored'),
      });
      onUpdated?.();
    },
  });

  useImperativeHandle(ref, () => ({
    open: (client, tab) => {
      setIsOpen(true);
      setClientProfile(client);
      setClientId(client.id);
      if (tab) {
        setDefaultTab(tab);
      }
    },
    openById: (id, tab) => {
      setIsOpen(true);
      setClientId(id);
      if (tab) {
        setDefaultTab(tab);
      }
    },
  }));

  useEffect(() => {
    refetchClient();
  }, [clientId]);

  useEffect(() => {
    if (!isOpen && clientId) {
      setClientId(undefined);
    }
  }, [isOpen]);

  const client = clientProfileById ? clientProfileById : clientProfile;

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-screen-xl">
          <DialogHeader>
            <DialogTitle>{t('Pages.AllClients.clientProfile')}</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>

          <Tabs defaultValue={defaultTab}>
            <TabsList className="w-full max-w-[700px]">
              <TabsTrigger className="w-full" value={ClientProfileTabs.INFO}>
                {t('Buttons.profileInfo')}
              </TabsTrigger>
              <TabsTrigger className="w-full" value={ClientProfileTabs.DOCUMENTS}>
                {t('Buttons.documents')}
              </TabsTrigger>
              <TabsTrigger className="w-full" value={ClientProfileTabs.SERVICE}>
                {t('Buttons.services')}
              </TabsTrigger>
              <TabsTrigger className="w-full" value={ClientProfileTabs.SHIFTS}>
                {t('Buttons.shifts')}
              </TabsTrigger>
              <TabsTrigger className="w-full" value={ClientProfileTabs.GUARDIANS}>
                {t('Buttons.guardians')}
              </TabsTrigger>
            </TabsList>
            <TabsContent value={ClientProfileTabs.INFO} className="pt-4">
              <div className="h-[calc(100vh-260px)]">
                {client && (
                  <ClientProfileTab
                    onEdit={isCanEdit ? () => manageClientDialogRef.current?.open(client) : undefined}
                    client={client}
                  />
                )}
              </div>
            </TabsContent>

            <TabsContent value={ClientProfileTabs.DOCUMENTS}>
              <div className="h-[calc(100vh-228px)]">
                {client && (
                  <AllDocumentsTable
                    tableClassName="h-[calc(100vh-376px)]"
                    clientId={client.id}
                    hideCreate={false}
                    hideTableSettings
                  />
                )}
              </div>
            </TabsContent>
            <TabsContent value={ClientProfileTabs.SERVICE}>
              <div className="h-[calc(100vh-228px)]">
                {client && (
                  <AllServicesTable tableClassName="h-[calc(100vh-376px)]" clientId={client.id} hideTableSettings />
                )}
              </div>
            </TabsContent>
            <TabsContent value={ClientProfileTabs.SHIFTS}>
              <div className="h-[calc(100vh-228px)]">
                {client && (
                  <AllShiftsTable
                    tableClassName="h-[calc(100vh-368px)]"
                    clientId={client.id}
                    hideCreate={false}
                    hideTableSettings
                  />
                )}
              </div>
            </TabsContent>
            <TabsContent value={ClientProfileTabs.GUARDIANS}>
              <div className="h-[calc(100vh-228px)]">
                {client && (
                  <AllGuardiansTable
                    hideLabel
                    tableClassName="h-[calc(100vh-376px)]"
                    clientId={client.id}
                    hideCreate={false}
                    hideTableSettings
                  />
                )}
              </div>
            </TabsContent>
          </Tabs>
        </DialogContent>
      </Dialog>
      <ManageClientDialog
        onUpdated={() => {
          refetchClient();
          onUpdated?.();
        }}
        onDelete={(id) => removeClient(id)}
        ref={manageClientDialogRef}
      />
    </>
  );
});

PreviewClientDialog.displayName = 'PreviewClientDialog';

export { PreviewClientDialog };
