'use client';

import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Input,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  GooglePlacesInput,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { cn, transformApiDateToViewDate, transformViewDateToApiDate } from '@/lib/utils';
import { Button } from '@/components/ui';
import { toast, ToastTypeEnums } from '@/components/common';
import {
  manageClientChildManuallyValidationSchema,
  ManageClientChildManuallyValidationSchema,
} from './manage-client-child-manually-validation-schema';
import { useCreateClientChildManuallyMutation } from '@/hooks/fetchers/mutations/clients/useCreateClientChildManuallyMutation';
import { useMask } from '@react-input/mask';
import { Client } from '../interfaces/clients.interface';
import { useUpdateClientMutation } from '@/hooks/fetchers/mutations/clients/useUpdateClientMutation';
import { DialogFooter } from '@/components/ui/dialog';
import { DATE_MASK, REGIONAL_CENTERS } from '@/common/constants';

interface Props {
  client?: Client;
  onDelete?: (value: ManageClientChildManuallyValidationSchema) => void;
  onCancel?: () => void;
  onRefresh: () => void;
}

export function ManageClientChildManuallyForm({ client, onDelete, onCancel, onRefresh }: Props) {
  const t = useTranslations();
  const inputDateRef = useMask({ ...DATE_MASK });

  const { mutate: create, isPending: isCreating } = useCreateClientChildManuallyMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.clientCreated'),
      });
      form.reset();
      onRefresh();
    },
  });

  const { mutate: update, isPending: isUpdating } = useUpdateClientMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.clientUpdated'),
      });
      onRefresh();
    },
  });

  const form = useForm<ManageClientChildManuallyValidationSchema>({
    mode: 'onTouched',
    resolver: zodResolver(manageClientChildManuallyValidationSchema),
    defaultValues: {
      firstName: client?.firstName ?? '',
      lastName: client?.lastName ?? '',
      caseManager: client?.caseManager ?? '',
      regionalCenter: client?.regionalCenter ?? '',
      address: client?.address ?? '',
      uci: client?.uci ?? '',
      dob: client?.dob ? transformApiDateToViewDate(client.dob) : '',
    },
  });

  const handleDelete = () => {
    if (onDelete) {
      onDelete(form.getValues());
    }
  };

  const onSubmit: SubmitHandler<ManageClientChildManuallyValidationSchema> = async (
    value: ManageClientChildManuallyValidationSchema
  ) => {
    value.dob = transformViewDateToApiDate(value.dob);
    if (client) {
      update({
        id: client.id,
        value,
      });
    } else {
      create(value);
    }
  };

  return (
    <Form {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <div className="h-[calc(100vh-300px)]">
          <div className="grid grid-cols-form-cols-2 gap-8">
            <div className="relative">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.firstName.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        placeholder={t('Forms.firstName.placeholder')}
                        {...field}
                        type="text"
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="lastName"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.lastName.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        {...field}
                        type="text"
                        placeholder={t('Forms.lastName.placeholder')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="caseManager"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.caseManager.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        {...field}
                        type="text"
                        placeholder={t('Forms.caseManager.placeholder')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="uci"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.uciNumber.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        {...field}
                        type="text"
                        placeholder={t('Forms.uciNumber.placeholder')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="dob"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.dateOfBirth.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        {...field}
                        ref={inputDateRef}
                        type="text"
                        placeholder={t('Forms.dateOfBirth.placeholder')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="address"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.address.label')}</FormLabel>
                    <FormControl>
                      <GooglePlacesInput
                        values={field.value}
                        onChanges={field.onChange}
                        className={cn('', !!fieldState.error && 'border-destructive')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="regionalCenter"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.regionalCenter.label')}</FormLabel>
                    <FormControl>
                      <Select value={field.value} onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue placeholder={t('Forms.regionalCenter.placeholderSelector')} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {REGIONAL_CENTERS.map((rc) => (
                            <SelectItem key={rc} value={rc}>
                              {rc}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>

        <DialogFooter className="mt-10 flex h-20 flex-row items-center justify-end space-x-2 border-t border-t-gray-300">
          {client ? (
            <Button onClick={handleDelete} variant="outlineDestructive" type="button" size="lg" className="mt-5">
              {t('Buttons.deleteClientAccount')}
            </Button>
          ) : (
            <Button onClick={onCancel} variant="ghost" type="button" size="lg" className="mt-5">
              {t('Buttons.cancel')}
            </Button>
          )}

          <Button type="submit" size="lg" className="mt-5" disabled={isCreating || isUpdating}>
            {t('Buttons.save')}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
