'use client';

import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input, Form, FormControl, FormField, FormItem, FormLabel } from '@/components/form';
import { useTranslations } from 'next-intl';
import { Button } from '@/components/ui';
import { Chips, toast, ToastTypeEnums } from '@/components/common';
import { Therapist } from '../interfaces/therapists.interface';
import { useEffect } from 'react';
import { DialogFooter } from '@/components/ui/dialog';
import { UpdatePayRatesValidationSchema, updatePayRatesValidationSchema } from './update-pay-rates-validation-schema';
import { getColorForServiceRole } from '@/lib/utils';
import { useTherapistQuery } from '@/hooks/fetchers/queries/therapists/useTherapistQuery';
import { useUpdatePayRatesMutation } from '@/hooks/fetchers/mutations/pay-rates/useUpdatePayRatesMutation';
import { ScrollArea } from '@radix-ui/react-scroll-area';
import { parsePayRates } from '../utils';

interface Props {
  therapist: Therapist;
  onUpdated: () => void;
}

export function UpdatePayRatesForm({ onUpdated, therapist }: Props) {
  const t = useTranslations();

  const { data: therapistFullInfo } = useTherapistQuery(therapist.id);

  const { mutate: update, isPending } = useUpdatePayRatesMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.payRateUpdated'),
      });
      onUpdated();
    },
  });

  const form = useForm<UpdatePayRatesValidationSchema>({
    mode: 'onTouched',
    resolver: zodResolver(updatePayRatesValidationSchema),
    defaultValues: {
      generalBasePayRate: 0,
      payRates: [
        {
          serviceRoleName: '',
          payRate: 0,
        },
      ],
    },
  });

  const { fields } = useFieldArray({ name: 'payRates', control: form.control });

  useEffect(() => {
    if (therapistFullInfo) {
      const payRatesValue = parsePayRates(therapistFullInfo);
      if (payRatesValue) {
        form.setValue('generalBasePayRate', payRatesValue.generalBasePayRate);
        form.setValue('payRates', payRatesValue.payRates);
      }
    }
  }, [therapistFullInfo]);

  const onSubmit: SubmitHandler<UpdatePayRatesValidationSchema> = async (value: UpdatePayRatesValidationSchema) => {
    update({
      therapistId: therapist.id,
      value,
    });
  };

  return (
    <Form {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)} className='h-[calc(100vh-244px)]'>
        <div className="flex flex-col pl-1 pr-6">
          <section>
            <h2 className="text-sm font-bold text-gray-950">{t('Pages.AllTherapists.generalBasePayRate')}</h2>
            <p className="mt-2 max-w-[564px] text-sm font-normal text-gray-400">
              {t('Pages.AllTherapists.generalBasePayRateDesc')}
            </p>
          </section>

          <div className="relative my-4 max-w-64">
            <FormField
              control={form.control}
              name="generalBasePayRate"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.generalBasePayRate.label')}</FormLabel>
                  <FormControl>
                    <Input
                      hasError={!!fieldState.error}
                      className="w-full"
                      placeholder={t('Forms.generalBasePayRate.placeholder')}
                      {...field}
                      type="number"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>

          {fields.length > 0 && (
            <section>
              <h2 className="text-sm font-bold text-gray-950">{t('Pages.AllTherapists.payRatePerServiceRole')}</h2>
              <p className="mt-2 max-w-[564px] text-sm font-normal text-gray-400">
                {t('Pages.AllTherapists.payRatePerServiceRoleDesc')}
              </p>
              <p className="mt-2 max-w-[564px] text-sm font-normal text-orange-400">
                {t('Pages.AllTherapists.payRatePerServiceRoleWarn')}
            </p>
            </section>
          )}

          <ScrollArea className="h-[calc(100vh-632px)] overflow-auto">
            <div className="relative my-4 space-y-2">
              {fields.map((field, index) => (
                <div key={field.id} className="flex w-[540px] flex-row items-end justify-between">
                  <Chips title={field.serviceRoleName} color={getColorForServiceRole(field.serviceRoleName)} />
                  <div className="w-full max-w-64">
                    <FormField
                      control={form.control}
                      name={`payRates.${index}.payRate`}
                      render={({ field, fieldState }) => (
                        <FormItem>
                          <FormLabel>{t('Forms.payRate.label')}</FormLabel>
                          <FormControl>
                            <Input
                              hasError={!!fieldState.error}
                              className="w-full"
                              placeholder={t('Forms.payRate.placeholder')}
                              {...field}
                              type="number"
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
              ))}
            </div>
          </ScrollArea>
        </div>

        <DialogFooter className="mt-10 flex h-20 flex-row items-center justify-end space-x-2 border-t border-t-gray-300">
          <Button type="submit" size="lg" className="mt-5" disabled={isPending}>
            {t('Buttons.save')}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
