import { Dialog } from '@/components/ui';
import { DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { DialogDescription } from '@radix-ui/react-dialog';

import { useTranslations } from 'next-intl';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { ConfirmDialog, ConfirmDialogRef } from '@/components/common';
import { Guardian } from '../interfaces/guardians.interface';
import { ManageGuardianForm } from './manage-guardian-form';
import { ManageGuardianValidationSchema } from './manage-guardian-validation-schema';

interface Props {
  onUpdated: () => void;
  onDelete: (id: string) => void;
}

export type ManageGuardianDialogRef = {
  open: (guardian: Guardian) => void;
};

const ManageGuardianDialog = forwardRef<ManageGuardianDialogRef, Props>(({ onUpdated, onDelete }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const t = useTranslations();
  const guardianRef = useRef<Guardian | null>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>();

  useImperativeHandle(ref, () => ({
    open: (guardian) => {
      setIsOpen(true);
      guardianRef.current = guardian;
    },
  }));

  const handleUpdateGuardian = () => {
    setIsOpen(false);
    onUpdated();
  };

  const handleDeleteGuardian = (account: ManageGuardianValidationSchema) => {
    if (guardianRef.current && guardianRef.current.id) {
      const value = { ...guardianRef.current, ...account };
      confirmDialogRef.current?.open({
        description: t('Toasts.confirmDeleteGuardian', { name: `${value.firstName} ${value.lastName}` }),
        value: value.id,
      });
    }
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className='w-full max-w-screen-xl'>
          <DialogHeader>
            <DialogTitle>{t('Pages.AllGuardians.guardianProfile')}</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          <div className="pt-4">
            {guardianRef.current && (
              <ManageGuardianForm
                onDelete={handleDeleteGuardian}
                guardian={guardianRef.current}
                onRefresh={handleUpdateGuardian}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        ref={confirmDialogRef}
        onConfirm={(id) => {
          onDelete(id);
          setIsOpen(false);
        }}
        title={t('Pages.AllGuardians.deleteGuardian')}
        type="delete"
      />
    </>
  );
});

ManageGuardianDialog.displayName = 'ManageGuardianDialog';

export { ManageGuardianDialog };
