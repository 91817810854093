import { ALPHABETIC_NUMBERS, DATE_MMDDYYYY } from '@/common/constants';
import { z } from 'zod';

export const manageClientChildManuallyValidationSchema = z.object({
  firstName: z
    .string()
    .min(1, { message: 'Forms.firstName.required' })
    .min(2, { message: 'Forms.firstName.min' })
    .max(50, { message: 'Forms.firstName.max' }),
    // .regex(USER_NAME, { message: 'Forms.firstName.onlyAlphabetic' }),
  caseManager: z.union([
    z.literal(''),
    z
      .string()
      .min(2, { message: 'Forms.caseManager.min' })
      .max(50, { message: 'Forms.caseManager.max' }),
      // .regex(USER_NAME, { message: 'Forms.caseManager.onlyAlphabetic' }),
  ]),
  lastName: z
    .string()
    .min(1, { message: 'Forms.lastName.required' })
    .min(2, { message: 'Forms.lastName.min' })
    .max(50, { message: 'Forms.lastName.max' }),
    // .regex(USER_NAME, { message: 'Forms.lastName.onlyAlphabetic' }),
  uci: z
    .string({ message: 'Forms.uciNumber.required' })
    .min(1, { message: 'Forms.uciNumber.required' })
    .regex(ALPHABETIC_NUMBERS, { message: 'Forms.uciNumber.invalid' }),
  dob: z
    .string()
    .min(1, { message: 'Forms.dateOfBirth.required' })
    .regex(DATE_MMDDYYYY, { message: 'Forms.dateOfBirth.invalid' }), // MM.DD.YYYY
  address: z.union([
    z.string().length(0), // Allows empty string
    z.string().min(5, { message: 'Forms.address.min' }).max(200, { message: 'Forms.address.max' }),
  ]),
  regionalCenter: z.string().min(1, { message: 'Forms.regionalCenter.required' }),
});

export type ManageClientChildManuallyValidationSchema = z.infer<typeof manageClientChildManuallyValidationSchema>;
