'use client';
import { Button } from '@/components/ui';
import { DialogFooter } from '@/components/ui/dialog';
import { cn, getColorForServiceRole } from '@/lib/utils';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { Therapist } from '../interfaces/therapists.interface';
import { parsePayRates, PayRates } from '../utils';
import { Chips, ScrollArea } from '@/components/common';
import { useIsAllowed } from '@/lib/RBAC';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';

interface Props {
  className?: string;
  therapist?: Therapist;
  onEdit: () => void;
}

export function TherapistPayRateTab({ therapist, className, onEdit }: Props) {
  const t = useTranslations();
  const { checkPermissions } = useIsAllowed();

  const [payRates, setPayRates] = useState<PayRates>({ generalBasePayRate: 0, payRates: [] });

  useEffect(() => {
    if (therapist) {
      const payRatesValue = parsePayRates(therapist);
      if (payRatesValue) {
        setPayRates(payRatesValue);
      }
    }
  }, [therapist]);

  return (
    <>
      <div className={cn('h-[calc(100vh-380px)]', className)}>
        <h2 className="mb-2 mt-4 text-sm font-bold text-gray-950">{t('Pages.AllTherapists.generalBasePayRate')}</h2>
        <p className="my-5 text-xl font-bold text-gray-600">${payRates.generalBasePayRate}</p>
        {payRates.payRates.length > 0 && (
          <h2 className="mb-2 mt-4 text-sm font-bold text-gray-950">
            {t('Pages.AllTherapists.payRatePerServiceRole')}
          </h2>
        )}

        <ScrollArea className="h-[calc(100vh-480px)] overflow-auto">
          <div className="mt-5 flex flex-col space-y-2">
            {payRates.payRates.map((rate) => (
              <div key={rate.serviceRoleName} className="flex w-96 flex-row items-center justify-between">
                <Chips title={rate.serviceRoleName} color={getColorForServiceRole(rate.serviceRoleName)} />
                <span className="text-base font-medium text-gray-600">${rate.payRate}</span>
              </div>
            ))}
          </div>
        </ScrollArea>
      </div>
      <DialogFooter className="mt-10 flex h-20 flex-row items-center justify-end space-x-2 border-t border-t-gray-300">
        {checkPermissions([PERMISSIONS_TYPES.SET_PAY_RATE]) ? (
          <Button type="button" size="lg" className="mt-5" onClick={onEdit}>
            {t('Buttons.edit')}
          </Button>
        ) : null}
      </DialogFooter>
    </>
  );
}
