import { Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';

import { useTranslations } from 'next-intl';
import { createRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Therapist } from '../interfaces/therapists.interface';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { TherapistProfileTabs } from '../enums/therapist-profile-tabs';
import { TherapistProfileTab } from './therapist-profile-tab';
import { useTherapistQuery } from '@/hooks/fetchers/queries/therapists/useTherapistQuery';
import { TherapistPayRateTab } from './therapist-pay-rate-tab';
import { AllTherapistsTable } from '../table/all-therapists-table';
import { ROLE_TYPES } from '@/lib/RBAC/enums/role-types';
import { AllShiftsTable } from '@/views/all-shifts/table/all-shifts-table';
import { AllServicesTable } from '@/views/all-services/table/all-services-table';
import { AllDocumentsTable } from '@/views/all-documents/table/all-documents-table';
import { ManageTherapistDialog, ManageTherapistDialogRef } from '../manage/manage-therapist.dialog';
import { useRemoveTherapistMutation } from '@/hooks/fetchers/mutations/therapists/useRemoveTherapistMutation';
import { toast, ToastAction, ToastTypeEnums } from '@/components/common';
import { useRemoveUndoTherapistMutation } from '@/hooks/fetchers/mutations/therapists/useRemoveUndoTherapistMutation';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';
import { useIsAllowed } from '@/lib/RBAC';

interface Props {
  onRemove?: (id: string) => void;
  onUpdated?: () => void;
}

export type PreviewTherapistDialogRef = {
  open: (account: Therapist) => void;
  openById: (id: string) => void;
};

const PreviewTherapistDialog = forwardRef<PreviewTherapistDialogRef, Props>(({ onRemove, onUpdated }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const t = useTranslations();
  const { checkPermissions } = useIsAllowed();
  const [accountId, setAccountId] = useState<string>();

  const manageTherapistDialogRef = createRef<ManageTherapistDialogRef>();
  const { data: therapistFullInfo, refetch: refetchTherapist } = useTherapistQuery(accountId, { enabled: true, refetchOnWindowFocus: isOpen });

  useImperativeHandle(ref, () => ({
    open: (account) => {
      setIsOpen(true);
      setAccountId(account.id);
    },
    openById: (id) => {
      setIsOpen(true);
      setAccountId(id);
    },
  }));

  useEffect(() => {
    if (!isOpen && accountId) {
      setAccountId(undefined);
    }
  }, [isOpen]);

  const { mutate: removeTherapist } = useRemoveTherapistMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successDeleted'),
        action: (
          <ToastAction onClick={() => removeUndoTherapist(data.id)} altText="Cancel remove">
            Undo action
          </ToastAction>
        ),
      });
      setIsOpen(false);
      onUpdated?.();
      onRemove?.(data.id);
    },
  });

  const { mutate: removeUndoTherapist } = useRemoveUndoTherapistMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successRestored'),
      });
      onUpdated?.();
    },
  });

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-screen-xl">
          <DialogHeader>
            <DialogTitle>{t('Pages.AllTherapists.therapistProfile')}</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          <Tabs defaultValue={TherapistProfileTabs.INFO}>
            <TabsList className="w-full max-w-[600px]">
              <TabsTrigger className="w-full" value={TherapistProfileTabs.INFO}>
                {t('Buttons.profileInfo')}
              </TabsTrigger>
              <TabsTrigger className="w-full" value={TherapistProfileTabs.DOCUMENTS}>
                {t('Buttons.documents')}
              </TabsTrigger>
              <TabsTrigger className="w-full" value={TherapistProfileTabs.SERVICE}>
                {t('Buttons.services')}
              </TabsTrigger>
              <TabsTrigger className="w-full" value={TherapistProfileTabs.SHIFTS}>
                {t('Buttons.shifts')}
              </TabsTrigger>

              {therapistFullInfo?.role === ROLE_TYPES.Therapist ||
              therapistFullInfo?.role === ROLE_TYPES['Therapist Limited'] ? (
                <TabsTrigger className="w-full" value={TherapistProfileTabs.ASSISTANTS}>
                  {t('Buttons.assistants')}
                </TabsTrigger>
              ) : null}

              {checkPermissions([PERMISSIONS_TYPES.SET_PAY_RATE]) && (
                <TabsTrigger className="w-full" value={TherapistProfileTabs.PAY_RATE}>
                  {t('Buttons.payRate')}
                </TabsTrigger>
              )}
            </TabsList>
            <TabsContent value={TherapistProfileTabs.INFO} className="pt-4">
              <div className="h-[calc(100vh-244px)]">
                {therapistFullInfo && (
                  <TherapistProfileTab
                    onRefresh={refetchTherapist}
                    onEdit={() => manageTherapistDialogRef.current?.open(therapistFullInfo)}
                    therapist={therapistFullInfo}
                  />
                )}
              </div>
            </TabsContent>

            <TabsContent value={TherapistProfileTabs.DOCUMENTS}>
              <div className="h-[calc(100vh-228px)]">
                {therapistFullInfo && (
                  <AllDocumentsTable
                    tableClassName="h-[calc(100vh-376px)]"
                    therapistId={therapistFullInfo.id}
                    hideCreate={false}
                    hideTableSettings
                  />
                )}
              </div>
            </TabsContent>
            <TabsContent value={TherapistProfileTabs.SERVICE}>
              <div className="h-[calc(100vh-228px)]">
                {therapistFullInfo && (
                  <AllServicesTable
                    tableClassName="h-[calc(100vh-376px)]"
                    therapistId={therapistFullInfo.id}
                    hideTableSettings
                    hideCreate={false}
                  />
                )}
              </div>
            </TabsContent>
            <TabsContent value={TherapistProfileTabs.SHIFTS}>
              <div className="h-[calc(100vh-228px)]">
                {therapistFullInfo && (
                  <AllShiftsTable
                    tableClassName="h-[calc(100vh-368px)]"
                    therapistId={therapistFullInfo.id}
                    hideCreate={false}
                    hideTableSettings
                  />
                )}
              </div>
            </TabsContent>
            <TabsContent value={TherapistProfileTabs.ASSISTANTS}>
              <div className="h-[calc(100vh-228px)]">
                {therapistFullInfo && (
                  <AllTherapistsTable
                    tableClassName="h-[calc(100vh-376px)]"
                    supervisorId={therapistFullInfo.id}
                    hideCreate
                    hideTableSettings
                    hidePreview
                  />
                )}
              </div>
            </TabsContent>
            <TabsContent value={TherapistProfileTabs.PAY_RATE} className="pt-4">
              <div className="h-[calc(100vh-260px)]">
                {therapistFullInfo && (
                  <TherapistPayRateTab
                    onEdit={() =>
                      manageTherapistDialogRef.current?.open(therapistFullInfo, TherapistProfileTabs.PAY_RATE)
                    }
                    therapist={therapistFullInfo}
                  />
                )}
              </div>
            </TabsContent>
          </Tabs>
        </DialogContent>
      </Dialog>
      <ManageTherapistDialog
        onUpdated={refetchTherapist}
        onDelete={(id) => removeTherapist(id)}
        ref={manageTherapistDialogRef}
      />
    </>
  );
});

PreviewTherapistDialog.displayName = 'PreviewTherapistDialog';

export { PreviewTherapistDialog };
