import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ServiceRole } from '@/views/service-roles/interfaces/service-role.interface';
import * as _ from 'lodash';

type Response = {
  data: ServiceRole[];
  totalCount: number;
};
interface Params {
  skip: number;
  take: number;
  search?: string;
  sortField?: string | null;
  sortOrder?: 'ASC' | 'DESC';
  regionalCenterFilter?: 'SGPRC' | 'NLACRC' | 'CVRC';
}

export type Options<T> = Omit<UseQueryOptions<unknown, DefaultError, T>, 'queryKey' | 'queryFn'>;

export const useServiceRolesQuery = <T = Response>(params: Params, options?: Options<T>) => {
  const queryParams = {
    skip: params.skip,
    take: params.take,
    search: params.search,
    sortField: params.sortField,
    sortOrder: params.sortOrder,
    regionalCenterFilter: params.regionalCenterFilter,
  };

  const cleanedQueryParams = _.omitBy(queryParams, value => _.isNil(value) || value === '');

  const _query = async (): Promise<T> => {
    const { data } = await axiosInterceptorInstance.get('service-roles', {
      params: cleanedQueryParams
    });
    return data;
  };

  return useQuery({
    queryKey: ['service-roles', queryParams],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
