import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { TABLE_TYPES } from '@/common/enums';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

interface RequestData {
  name: TABLE_TYPES;
  filters: any;
}

export type Options = Omit<UseMutationOptions<string, DefaultError, RequestData>, 'mutationKey' | 'mutationFn'>;

export const useTableFilterDefaultMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (value: RequestData): Promise<string> => {
    const { data } = await axiosInterceptorInstance.post(`table-filters-configuration`, value);
    return data;
  };

  return useMutation({
    mutationKey: ['table-filters-configuration'],
    mutationFn: _mutation,
    onSettled: ((data, error) => {
      if (!error) {
        queryClient.invalidateQueries({ queryKey: ['table-filters-configuration'] });
      }
    }),
    ...options,
  });
};
