import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { ManageClientChildManuallyValidationSchema } from '@/views/all-clients/manage/manage-client-child-manually-validation-schema';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import * as _ from 'lodash';

type Request = {
  id: string;
  value: ManageClientChildManuallyValidationSchema;
};

export type Options = Omit<UseMutationOptions<string, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

export const useUpdateClientMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async ({ id, value }: Request): Promise<string> => {
    const parsedValue = {
      ...value,
      address: value.address === '' ? null : value.address,
      caseManager: value.caseManager === '' ? null : value.caseManager,
    };
    const body = _.omitBy(parsedValue, v => v === '');
    const { data } = await axiosInterceptorInstance.patch(`clients/${id}`, body);
    return data;
  };

  return useMutation({
    mutationKey: ['clients'],
    mutationFn: _mutation,
    onSettled: (() => {
      queryClient.invalidateQueries({ queryKey: ['clients'] });
      queryClient.invalidateQueries({ queryKey: ['guardians'] });
      queryClient.invalidateQueries({ queryKey: ['services'] });
      queryClient.invalidateQueries({ queryKey: ['therapists'] });
      queryClient.invalidateQueries({ queryKey: ['documents'] });
      queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
    }),
    ...options,
  });
};
