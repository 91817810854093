'use client';
import { Button } from '@/components/ui';
import { DialogFooter } from '@/components/ui/dialog';
import { cn, compileLastName, openAddressInGoogleMaps, transformApiDateToViewDate } from '@/lib/utils';
import { useTranslations } from 'next-intl';
import { LabelValueItem } from '@/components/common';
import { Client } from '../interfaces/clients.interface';
import { useIsAllowed } from '@/lib/RBAC';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';

interface Props {
  className?: string;
  client?: Client;
  onEdit?: () => void;
}

export function ClientProfileTab({ client, className, onEdit }: Props) {
  const t = useTranslations();
  const { checkPermissions } = useIsAllowed();

  return (
    <div className="relative">
      <div className={cn('h-[calc(100vh-380px)]', className)}>
        <h2 className="mt-4 text-sm font-bold text-gray-950">{t('Common.personalInformation')}</h2>
        <div className="mt-4 grid grid-cols-form-cols-2 gap-8">
          <LabelValueItem label={t('Forms.firstName.label')} value={client?.firstName} />
          <LabelValueItem label={t('Forms.lastName.label')} value={compileLastName(client)} />
          <LabelValueItem label={t('Forms.caseManager.label')} value={client?.caseManager} />
          <LabelValueItem label={t('Forms.dateOfBirth.label')} value={transformApiDateToViewDate(client?.dob)} />
          <LabelValueItem label={t('Forms.uciNumber.label')} value={client?.uci} />
          <LabelValueItem
            label={t('Forms.address.label')}
            value={client?.address}
            onClickByValue={client?.address ? () => openAddressInGoogleMaps(client?.address) : undefined}
          />
          <LabelValueItem label={t('Forms.regionalCenter.label')} value={client?.regionalCenter} />
        </div>
      </div>

      <DialogFooter className="mt-10 flex h-20 flex-row items-center justify-end space-x-2 border-t border-t-gray-300">
        {!client?.deactivatedAt && (
          <>
            {checkPermissions([PERMISSIONS_TYPES.EDIT_CLIENT]) && onEdit ? (
              <Button type="button" size="lg" className="mt-5" onClick={onEdit}>
                {t('Buttons.edit')}
              </Button>
            ) : null}
          </>
        )}
      </DialogFooter>
    </div>
  );
}
