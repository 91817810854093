'use client';
import {
  ColumnOrderState,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { ChangeEvent, createRef, useEffect, useRef, useState } from 'react';
import { Plus } from 'lucide-react';
import {
  ConfirmDialog,
  Pagination,
  Sheet,
  SheetContent,
  SheetDescription,
  SheetTitle,
  SheetTrigger,
  toast,
  ToastAction,
  ToastTypeEnums,
  ToggleFilter,
  ColumnAdvance,
  TableColumnSettings,
  clearFilter,
  parseFilterToArrayOfTagsData,
  CommonTable,
  SimpleSearch,
  TableFilter,
  ConfirmDialogRef,
} from '@/components/common';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { Button } from '@/components/ui';
import { getAllTherapistsColumns } from './all-therapists-columns';
import { CreateTherapistForm } from '../manage/create-therapist-form';
import { useTranslations } from 'next-intl';
import { Therapist } from '../interfaces/therapists.interface';
import { useTherapistsQuery } from '@/hooks/fetchers/queries/therapists/useTherapistsQuery';
import { useRemoveTherapistMutation } from '@/hooks/fetchers/mutations/therapists/useRemoveTherapistMutation';
import { useRemoveUndoTherapistMutation } from '@/hooks/fetchers/mutations/therapists/useRemoveUndoTherapistMutation';
import { PreviewTherapistDialog, PreviewTherapistDialogRef } from '../preview/preview-therapist.dialog';
import { AllTherapistsFilterForm, FilterAllTherapistsParams } from '../header-filter/all-therapists-filter-form';
import { cn } from '@/lib/utils';
import { useIsAllowed } from '@/lib/RBAC';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';
import { useTableSettingsQuery } from '@/hooks/fetchers/queries/useTableSettingsQuery';
import { useTableSettingsMutation } from '@/hooks/fetchers/mutations/useTableSettingsMutation';
import { TABLE_TYPES } from '@/common/enums';
import { useTableFilterDefaultMutation } from '@/hooks/fetchers/mutations/useTableFilterDefaultMutation';
import { useTableFilterDefaultQuery } from '@/hooks/fetchers/queries/useTableFilterDefaultQuery';
import { allTherapistsSortingHelper } from './all-therapists-sorting-helper';

const PER_PAGE = 100;

interface Params {
  supervisorId?: string;
  tableClassName?: string;
  hideCreate?: boolean;
  hidePreview?: boolean;
  hideTableSettings?: boolean;
}

export function AllTherapistsTable({
  supervisorId,
  tableClassName,
  hideCreate,
  hidePreview,
  hideTableSettings,
}: Params) {
  const t = useTranslations();
  const [data, setData] = useState<Therapist[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [createSheetOpen, setCreateSheetOpen] = useState(false);
  const [filter, setFilter] = useState<FilterAllTherapistsParams>({});
  const [search, setSearch] = useState('');
  const [showDeleted, setShowDeleted] = useState(false);
  const [pagination, setPagination] = useState<{ page: number; perPage: number }>({
    page: 0,
    perPage: PER_PAGE,
  });
  const previewTherapistDialogRef = createRef<PreviewTherapistDialogRef>();
  const confirmDialogRef = useRef<ConfirmDialogRef>();
  const { checkPermissions } = useIsAllowed();

  const { data: tableConfig } = useTableSettingsQuery(TABLE_TYPES.ALL_THERAPISTS);
  const { mutate: setTableConfig } = useTableSettingsMutation();
  const { data: tableDefFilters } = useTableFilterDefaultQuery(TABLE_TYPES.ALL_THERAPISTS);
  const { mutate: setTableDefFilters } = useTableFilterDefaultMutation();
  const {
    data: tableData,
    refetch: refetchTherapists,
    isLoading,
  } = useTherapistsQuery({
    skip: pagination.page * pagination.perPage,
    take: pagination.perPage,
    search,
    showDeleted,
    supervisorId: supervisorId,
    ...filter,
    ...allTherapistsSortingHelper(sorting),
  });

  useEffect(() => {
    if (tableConfig && tableConfig.settings.length > 0) {
      table.getAllLeafColumns().map((c: ColumnAdvance<Therapist>) => {
        const isVisible = tableConfig.settings.find((col) => col.title === c.id)?.isVisible;
        c.toggleVisibility(isVisible); // Change visible columns
      });
      table.setColumnOrder(tableConfig.settings.map((col) => col.title)); // Change order columns by ids
    }
  }, [JSON.stringify(tableConfig)]);

  useEffect(() => {
    if (tableDefFilters && tableDefFilters.filters) {
      setFilter(tableDefFilters.filters);
    }
  }, [JSON.stringify(tableDefFilters)]);

  useEffect(() => {
    if (tableData) {
      setData(tableData?.data);
    }
  }, [tableData]);

  useEffect(() => {
    if (filter) {
      setPagination((prev) => ({ ...prev, page: 0 }));
    }
  }, [JSON.stringify(filter)]);

  const { mutate: removeTherapist } = useRemoveTherapistMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successDeleted'),
        action: (
          <ToastAction onClick={() => removeUndoTherapist(data.id)} altText="Cancel remove">
            Undo action
          </ToastAction>
        ),
      });
      refetchTherapists();
    },
  });

  const { mutate: removeUndoTherapist } = useRemoveUndoTherapistMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successRestored'),
      });
      refetchTherapists();
    },
  });

  const handleConfirmRemove = (row: Row<Therapist>) => {
    confirmDialogRef.current?.open({
      description: t('Toasts.confirmDeleteAccount', { name: `${row.original.firstName} ${row.original.lastName}` }),
      value: row.original.id,
    });
  };

  const table = useReactTable({
    columns: getAllTherapistsColumns({
      t,
      onConfirmRemove: handleConfirmRemove,
      isCanRemove: checkPermissions([PERMISSIONS_TYPES.SOFT_DELETE_THERAPIST_PROFILE]),
    }),
    data,
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting, //optionally control sorting state in your own scope for easy access
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    manualSorting: true,
    state: {
      sorting,
      columnVisibility,
      columnOrder,
    },
  });

  const handleChangeColumnSettings = (columnIds: ColumnAdvance<Therapist>[]) => {
    setTableConfig({
      name: TABLE_TYPES.ALL_THERAPISTS,
      settings: columnIds.map((col) => ({ isVisible: col.isVisible, title: col.id })) as {
        isVisible: boolean;
        title: string;
      }[],
    });
    const ids = columnIds.map((c) => {
      c.toggleVisibility(c.isVisible); // Change visible columns
      return c.id;
    });
    table.setColumnOrder(ids); // Change order columns by ids
  };

  const handleSearchFilter = (event?: ChangeEvent<HTMLInputElement>) => {
    const search = event?.target.value || '';
    setPagination((prev) => ({
      ...prev,
      page: 0,
    }));
    setSearch(search);
  };

  const columnsIds = table.getAllLeafColumns().map((column: ColumnAdvance<Therapist>) => {
    column.isVisible = column.getIsVisible();
    return column;
  });

  return (
    <>
      <header className="flex flex-row items-center justify-between py-2">
        <TableFilter
          filter={filter}
          clearFilter={clearFilter}
          parseFilterToArrayOfTagsData={parseFilterToArrayOfTagsData}
          onUpdateFilter={setFilter}
          onSaveAsDefaultFilter={(filters) => {
            setTableDefFilters({
              name: TABLE_TYPES.ALL_THERAPISTS,
              filters,
            });
          }}
          formComponent={AllTherapistsFilterForm}
        />

        <div className="flex flex-row items-center space-x-2">
          <SimpleSearch onSearchChange={handleSearchFilter} />
          {!hideCreate && checkPermissions([PERMISSIONS_TYPES.CREATE_THERAPIST]) ? (
            <Sheet open={createSheetOpen} onOpenChange={setCreateSheetOpen}>
              <SheetTrigger asChild>
                <Button size="sm">
                  <Plus className="mr-1 size-4" /> {t('Buttons.addTherapist')}
                </Button>
              </SheetTrigger>
              {/* @INFO: tabIndex={undefined} Fix issue: Accessiblity Problem With Scroll Area and Forms */}
              <SheetContent tabIndex={undefined} onOpenAutoFocus={(e) => e.preventDefault()}>
                <SheetTitle>
                  <VisuallyHidden.Root>Add New Therapist</VisuallyHidden.Root>
                </SheetTitle>
                <SheetDescription></SheetDescription>
                <header className="flex h-16 flex-row items-center bg-primary p-6">
                  <h3 className="text-base font-medium text-white">{t('Pages.AllTherapists.addNewTherapist')}</h3>
                </header>
                <div className="w-full p-6 pr-0">
                  <CreateTherapistForm
                    onCancel={() => setCreateSheetOpen(false)}
                    onUpdate={() => {
                      setCreateSheetOpen(false);
                      refetchTherapists();
                    }}
                  />
                </div>
              </SheetContent>
            </Sheet>
          ) : null}
          {!hideTableSettings && <TableColumnSettings columnsIds={columnsIds} onChange={handleChangeColumnSettings} />}
        </div>
      </header>

      <div className="mb-2 grid h-10 w-full max-w-60 grid-cols-2 gap-1">
        {checkPermissions([PERMISSIONS_TYPES.VIEW_FILTER_ARCHIVED]) && (
          <>
            <ToggleFilter label={t('Buttons.active')} onChange={() => setShowDeleted(false)} isActive={!showDeleted} />
            <ToggleFilter label={t('Buttons.archived')} onChange={() => setShowDeleted(true)} isActive={showDeleted} />
          </>
        )}
      </div>

      <div className={cn('relative h-[calc(100vh-296px)] max-w-full overflow-auto', tableClassName)}>
        <div className="absolute w-full">
          <CommonTable
            table={table}
            onClickByRow={
              hidePreview ? undefined : (rowOriginal) => previewTherapistDialogRef.current?.open(rowOriginal)
            }
          />
        </div>
      </div>

      {tableData && tableData.totalCount > 0 && (
        <Pagination
          className="mt-1"
          changeCurrentPage={(page) =>
            setPagination((prev) => ({
              ...prev,
              page: page - 1,
            }))
          }
          totalSize={tableData && tableData.totalCount ? tableData.totalCount : 0}
          sizePerPage={pagination.perPage}
          currentPage={pagination.page + 1}
        />
      )}

      {isLoading && (
        <div className="flex h-9 w-full items-center justify-center">
          <span className="mt-1 text-sm text-gray-400">{t('Common.loadingWait')}</span>
        </div>
      )}

      <ConfirmDialog
        ref={confirmDialogRef}
        onConfirm={(id) => removeTherapist(id)}
        title={t('Common.deleteAccount')}
        type="delete"
      />

      <PreviewTherapistDialog ref={previewTherapistDialogRef} />
    </>
  );
}
