import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DOCUMENT_STATUS_TYPES, SPECIALITY_CODES } from '@/common/enums';
import { Document } from '@/views/all-documents/interfaces/document.interface';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as _ from 'lodash';

type Response = {
  data: Document[];
  totalCount: number;
};
interface Params {
  skip: number;
  take: number;
  showDeleted?: boolean;
  sortField?: string | null;
  sortOrder?: 'ASC' | 'DESC';
  search?: string;
  clientId?: string;
  serviceId?: string;
  therapistId?: string;
  status?: string;
  reportType?: string;
  dueDate?: 'Last month' | 'This month' | 'Next month';
    specialty?: SPECIALITY_CODES;
  regionalCenter?: string;
}

export type Options<T> = Omit<UseQueryOptions<unknown, DefaultError, T>, 'queryKey' | 'queryFn'>;

export const useDocumentsQuery = <T = Response>(params: Params, options?: Options<T>) => {

  const queryParams = {
    skip: params.skip,
    take: params.take,
    clientId: params.clientId,
    showDeleted: params.showDeleted,
    serviceId: params.serviceId,
    'therapistIds[]': params.therapistId ? [params.therapistId] : [],
    sortField: params.sortField,
    sortOrder: params.sortOrder,
    search: params.search,
    documentStatus: params.status === DOCUMENT_STATUS_TYPES.Expired ? DOCUMENT_STATUS_TYPES['Past due'] : params.status, // @TODO: Change Past due to Expired on BE
    documentType: params.reportType,
    specialtyFilter: params.specialty,
    dueDateFilter: params.dueDate,
    regionalCenter: params.regionalCenter,
  };

  const cleanedQueryParams = _.omitBy(queryParams, value => _.isNil(value) || value === '');

  const _query = async (): Promise<Response> => {
    const { data } = await axiosInterceptorInstance.get('documents', {
      params: cleanedQueryParams
    });
    return data;
  };

  return useQuery({
    queryKey: ['documents', queryParams],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
