import { ColumnDef, Row } from '@tanstack/react-table';
import { compileFullName, getColorForServiceRole } from '@/lib/utils';
import { Trash2 } from 'lucide-react';
import { Chips } from '@/components/common';
import { Colors } from '@/common/enums';
import { Client, Service, Shift, Therapist } from '../interfaces/shift.interface';
import { SHIFT_STATUS_COLORS, USER_ROLES_COLORS } from '@/common/constants';
import { ROLE_TYPES } from '@/lib/RBAC/enums/role-types';
import dayjs from '@/lib/dayjsConfig';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';

type Params = {
  t: any;
  onConfirmRemove: (row: Row<Shift>) => void;
  onPreviewClient: (client: Client) => void;
  onPreviewService: (service: Service) => void;
  onPreviewTherapist: (therapist: Therapist) => void;
  checkPermissions: (permissions: PERMISSIONS_TYPES[]) => boolean;
};

export const getAllShiftsColumns = ({
  t,
  checkPermissions,
  onConfirmRemove,
  onPreviewClient,
  onPreviewService,
  onPreviewTherapist,
}: Params): ColumnDef<Shift>[] => {
  const columns: ColumnDef<Shift>[] = [
    {
      id: 'Service',
      accessorKey: 'service',
      header: () => <span>{t('Tables.service')}</span>,
      cell: ({ row }) => {
        if (row.original.service) {
          return (
            <Chips
              onClickAction={(e) => {
                onPreviewService(row.original.service as Service);
                e.stopPropagation();
              }}
              color={getColorForServiceRole(row.original.serviceRole.title, row.original.serviceRole.speciality.code)}
              title={row.original.service.name}
            />
          );
        }
        return <></>;
      },
      size: 250,
      enableSorting: false,
      enablePinning: false,
    },
    {
      id: 'Client',
      accessorKey: 'client',
      header: () => <span>{t('Tables.client')}</span>,
      cell: ({ row }) => {
        if (row.original.client) {
          return (
            <Chips
              onClickAction={(e) => {
                onPreviewClient(row.original.client);
                e.stopPropagation();
              }}
              color={Colors.indigo}
              title={compileFullName(row.original.client)}
            />
          );
        }
        return <></>;
      },
      size: 250,
      enableSorting: true,
      enablePinning: false,
    },
    {
      id: 'Service Role',
      header: () => <span>{t('Tables.serviceRole')}</span>,
      cell: ({ row }) => {
        if (row.original.serviceRole) {
          const sr = row.original.serviceRole;
          return <Chips color={getColorForServiceRole(sr.title, sr.speciality.code)} title={sr.title} />;
        }
        return <></>;
      },
      enableSorting: false,
      enablePinning: false,
      size: 250,
    },
    {
      id: 'Therapist',
      accessorKey: 'type',
      header: () => <span>{t('Tables.therapist')}</span>,
      cell: ({ row }) => {
        if (row.original.therapist) {
          return (
            <Chips
              onClickAction={
                checkPermissions([PERMISSIONS_TYPES.PREVIEW_THERAPIST_PROFILE])
                  ? (e) => {
                      onPreviewTherapist(row.original.therapist as Therapist);
                      e.stopPropagation();
                    }
                  : undefined
              }
              color={USER_ROLES_COLORS[ROLE_TYPES.Therapist]}
              title={compileFullName(row.original.therapist)}
            />
          );
        }
        return <></>;
      },
      size: 250,
      enableSorting: true,
      enablePinning: false,
    },
    {
      id: 'Date',
      accessorFn: (row) => dayjs(row.startAt).format('MM.DD.YYYY'),
      header: () => <span>{t('Tables.date')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 120,
    },
    {
      id: 'Clock in Time',
      accessorKey: 'clockIn',
      accessorFn: (row) => (row.clockIn ? dayjs(row.clockIn).utcOffset(dayjs().format('Z')).format('h:mm A') : ''),
      header: () => <span>{t('Tables.clockInTime')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 120,
    },
    {
      id: 'Clock out Time',
      accessorKey: 'clockOut',
      accessorFn: (row) => (row.clockOut ? dayjs(row.clockOut).utcOffset(dayjs().format('Z')).format('h:mm A') : ''),
      header: () => <span>{t('Tables.clockOutTime')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 140,
    },
    {
      id: 'Start Time',
      accessorKey: 'startTime',
      accessorFn: (row) => (row.startAt ? dayjs(row.startAt).utcOffset(dayjs().format('Z')).format('h:mm A') : ''),
      header: () => <span>{t('Tables.startTime')}</span>,
      enableSorting: true,
      enablePinning: false,
      size: 120,
    },
    {
      id: 'End Time',
      accessorKey: 'endTime',
      accessorFn: (row) => (row.endAt ? dayjs(row.endAt).utcOffset(dayjs().format('Z')).format('h:mm A') : ''),
      header: () => <span>{t('Tables.endTime')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 120,
    },
    {
      id: 'Status',
      accessorKey: 'status',
      header: () => <span>{t('Tables.status')}</span>,
      cell: ({ row }) => {
        if (row.original.status) {
          return <Chips color={SHIFT_STATUS_COLORS[row.original.status]} title={row.original.status} />;
        } else {
          return <></>;
        }
      },
      enableSorting: false,
      enablePinning: false,
      size: 250,
    },
  ];

  if (checkPermissions([PERMISSIONS_TYPES.SOFT_DELETE_SHIFT])) {
    columns.push({
      id: 'Remove',
      enableSorting: false,
      enablePinning: true,
      cell: ({ row }) => (
        <Trash2
          onClick={(e) => {
            onConfirmRemove(row);
            e.stopPropagation();
          }}
          className="w-[18px] cursor-pointer text-gray-400"
        />
      ),
      size: 50,
    });
  }

  return columns;
};
